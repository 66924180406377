import React, { Fragment, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import Loader from "./Layouts/Loader/Loader";

const App = lazy(() => import("./components/app"));
const Dashboard = lazy(() => import("./components/Dashboard/Dashboard"));
const Login = lazy(() => import("./components/Authentication/Login/Login"));
const SilentLogin = lazy(() => import("./components/Authentication/silent-login"));
const ForgetPassword = lazy(() => import("./components/Pages/ForgetPassword"));
const AuthenticationPage = lazy(() =>
  import("./components/AuthenticationPage")
);

const Editprofile = lazy(() =>
  import("./components/Pages/Editprofile/Editprofile")
);
const MyLinks = lazy(() =>
  import("./components/Pages/MarkettingMaterial/MyLinks")
);
const BankAccounts = lazy(() =>
  import("./components/Pages/BankAccounts/BankAccounts")
);
const Wallets = lazy(() => import("./components/Pages/Wallets/Wallets"));
const IBList = lazy(() => import("./components/Pages/IB/IbList"));
const SubIbProfile = lazy(() =>
  import("./components/Pages/SubIbProfile/SubIbProfile")
);
const SubIbClient = lazy(() =>
  import("./components/Pages/SubIbProfile/SubIbClient")
);
const ClientAccounts = lazy(() =>
  import("./components/Pages/ClientAccounts/Account")
);
const Logs = lazy(() => import("./components/Pages/Logs/Logs"));
const Kyc = lazy(() => import("./components/Pages/Kyc/Kyc"));
const LiveAccounts = lazy(() =>
  import("./components/Pages/LiveAccounts/LiveAccounts")
);
const MyClients = lazy(() => import("./components/Pages/MyClients/Clients"));
const MyProfile = lazy(() =>
  import("./components/Pages/MyAggrement/Agreements")
);
const Withdrawal = lazy(() => import("./components/Pages/Withdrawal/index"));
const MyLeads = lazy(() => import("./components/Pages/MyLeads/Leads"));
const DailyRevenue = lazy(() =>
  import("./components/Pages/Revenue/DailyRevenue")
);
const MonthlyRevenue = lazy(() =>
  import("./components/Pages/Revenue/MonthlyRevenue")
);
const FullRevenue = lazy(() =>
  import("./components/Pages/Revenue/FullRevenue")
);
const DemoAccounts = lazy(() =>
  import("./components/Pages/DemoAccounts/DemoAccounts")
);
const ChangePassword = lazy(() =>
  import("./components/Pages/ChangePassword/index")
);
const Payout = lazy(() => import("./components/Pages/Payout/Payout"));
const Deposit = lazy(() => import("./components/Pages/Deposit"));
// const SolidPay = lazy(() => import("./components/Pages/Deposit/SolidPayment"));
const InternalTransfer = lazy(() =>
  import("./components/Pages/Internal-transfer")
);
const Report = lazy(() => import("./components/Pages/Report"));
const Trades = lazy(() => import("./components/Pages/Trades"));
const Platforms = lazy(() => import("./components/Pages/Platforms/Platforms"));

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Fragment>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path={`${process.env.PUBLIC_URL}/`}
              element={<AuthenticationPage />}
            >
              <Route path={`${process.env.PUBLIC_URL}/`} element={<Login />} />
              <Route
                path={`${process.env.PUBLIC_URL}/login`}
                element={<Login />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/silent-login`}
                element={<SilentLogin />}
              />
              {/* <Route path={`${process.env.PUBLIC_URL}/authentication/register`} element={<Register />} />*/}
              <Route
                path={`${process.env.PUBLIC_URL}/forget-password`}
                element={<ForgetPassword />}
              />
            </Route>

            <Route element={<App />}>
              <Route
                path={`${process.env.PUBLIC_URL}/dashboard`}
                element={<Dashboard />}
              />
              <Route>
                <Route
                  path={`${process.env.PUBLIC_URL}/profile`}
                  element={<Editprofile />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/my-links`}
                  element={<MyLinks />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/transactions/bank-accounts`}
                  element={<BankAccounts />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/transactions/wallets`}
                  element={<Wallets />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/subib/ib-hierarchy`}
                  element={<IBList />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/partnership/client-accounts`}
                  element={<ClientAccounts />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/partnership/client-accounts/:id`}
                  element={<ClientAccounts />}
                />,
                <Route
                  path={`${process.env.PUBLIC_URL}/subib/sub-ib-client`}
                  element={<SubIbClient />}
                />,
                <Route
                  path={`${process.env.PUBLIC_URL}/subib/sub-ib-client/:id`}
                  element={<SubIbClient />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/subib/sub-ib-profile`}
                  element={<SubIbProfile />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/profile/logs`}
                  element={<Logs />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/profile/kyc`}
                  element={<Kyc />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/partnership/myclient`}
                  element={<MyClients />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/partnership/myprofile`}
                  element={<MyProfile />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/partnership/mylead`}
                  element={<MyLeads />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/revenue/daily-revenue`}
                  element={<DailyRevenue />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/revenue/monthly-revenue`}
                  element={<MonthlyRevenue />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/revenue/full-revenue`}
                  element={<FullRevenue />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/accounts/live`}
                  element={<LiveAccounts />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/accounts/demo`}
                  element={<DemoAccounts />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/accounts/change-password`}
                  element={<ChangePassword />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/transactions/deposit`}
                  element={<Deposit />}
                />
                ,
                {/* <Route
                  path={`${process.env.PUBLIC_URL}/transactions/deposit/solidPay`}
                  element={<SolidPay />}
                />
                , */}
                <Route
                  path={`${process.env.PUBLIC_URL}/transactions/payout-request`}
                  element={<Withdrawal />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/transactions/payout`}
                  element={<Payout />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/transactions/internal-transfer`}
                  element={<InternalTransfer />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/reports`}
                  element={<Report />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/trades`}
                  element={<Trades />}
                />
                ,
                <Route
                  path={`${process.env.PUBLIC_URL}/platform`}
                  element={<Platforms />}
                />
              </Route>
            </Route>
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </Fragment>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
